import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import { getRaf } from "@app";
import { easeInOutQuint } from "/utils/easing.js";
import clamp from "/utils/clamp.js";
import map from "/utils/map.js";
import Model from "./Model";

import "./style.css";

export default class Canvas {
  static selector = "#canvas";

  constructor(block) {
    this.block = block;

    this.progress = 1;

    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    //Scene
    this.scene = new THREE.Scene();

    // Camera
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.windowSizes.width / this.windowSizes.height,
      0.1,
      100
    );
    this.camera.position.z = 20;
    this.camera.fov =
      2 * Math.atan(this.windowSizes.height / 2 / 20) * (180 / Math.PI);
    this.scene.add(this.camera);

    // Controls
    this.controls = new OrbitControls(this.camera, this.block);
    this.controls.target.set(0, 0.75, 0);
    this.controls.enableDamping = true;

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.block,
      antialias: true,
      alpha: true,
    });
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // Geometry
    this.geometry = new THREE.PlaneGeometry(
      //this.windowSizes.width,
      //this.windowSizes.height,
      1,
      1,
      50,
      50
    );

    // Material
    this.material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0,
    });

    // Mesh
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
    this.mesh.scale.set(this.windowSizes.width, this.windowSizes.height, 1);

    // Models Loader
    this.models = [];
    this.gltfLoader = new GLTFLoader();

    // Raycaster
    this.raycaster = new THREE.Raycaster();

    this.clock = new THREE.Clock();
  }

  getFov = (fov) => {
    const planeAspectRatio = 1.8;
    const currentAspectRatio = window.innerWidth / window.innerHeight;
    let fovToReturn = 0;
    const MathUtils = THREE.MathUtils;

    if (currentAspectRatio > planeAspectRatio) {
      /**too large*/
      fovToReturn = fov;
    } else {
      /**adjust fov*/
      const cameraHeight = Math.tan(MathUtils.degToRad(fov / 2));
      const ratio = currentAspectRatio / planeAspectRatio;
      const newCameraHeight = cameraHeight / ratio;
      fovToReturn = MathUtils.radToDeg(Math.atan(newCameraHeight)) * 2;
    }
    return fovToReturn;
  };

  showModel = (slug) => {
    const model = this.models.find((item) => {
      return item.slug === slug;
    });

    if (model && model.show) {
      model.show();
    }
  };

  hideModel = (slug) => {
    const model = this.models.find((item) => {
      return item.slug === slug;
    });

    if (model && model.hide) {
      model.hide();
    }
  };

  addModel = (filename, position, slug) => {
    this.gltfLoader.load(filename, (gltf) => {
      const model = gltf.scene.children[0];
      model.material.format = THREE.RGBAFormat;
      this.scene.add(model);
      const newModel = new Model(model, slug, position);
      this.models.push(newModel);
      this.setModelPosition(newModel);
    });
  };

  updateModelPosition = (slug, position) => {
    const model = this.models.filter((item) => item.slug === slug)[0];
    if (model) {
      model.position = position;
      this.setModelPosition(model);
    }
  };

  setModelPosition = (model) => {
    const position = model.position;
    const coord = new THREE.Vector2();

    /* coord.x = 200 / window.innerWidth;
    coord.y = 200 / window.innerHeight;
    this.raycaster.setFromCamera(coord, this.camera);
    const intersects1 = this.raycaster.intersectObject(this.mesh);
    for (var i = 0; i < intersects1.length; i++) {
      //console.log(intersects1[i].point);
      model.model.scale.set(
        intersects1[i].point.x * model.model.scale.x,
        intersects1[i].point.x * model.model.scale.x,
        1
      );
      console.log(model.model.scale);
      //model.model.scale.set(1, 1, 1);
      //model.setPosition(intersects1[i].point.x, intersects1[i].point.y);
    }
*/
    coord.x = (position.left / window.innerWidth) * 2 - 1;
    coord.y = -(position.top / window.innerHeight) * 2 + 1;

    this.raycaster.setFromCamera(coord, this.camera);
    const intersects = this.raycaster.intersectObject(this.mesh);

    for (var i = 0; i < intersects.length; i++) {
      model.setPosition(intersects[i].point.x, intersects[i].point.y);
    }
  };

  render = () => {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  };

  onResize = () => {
    this.windowSizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    if (this.mesh) {
      this.mesh.scale.set(this.windowSizes.width, this.windowSizes.height, 1);
    }

    /*this.models.forEach((model) => {
      this.setModelPosition(model.model, model.position);
    });*/

    // Update camera
    this.camera.aspect = this.windowSizes.width / this.windowSizes.height;
    this.camera.fov = this.getFov(30);
    this.camera.updateProjectionMatrix();

    if (this.mesh) {
      this.mesh.scale.set(this.windowSizes.width, this.windowSizes.height, 1);
    }

    this.renderer.setSize(this.windowSizes.width, this.windowSizes.height);
  };

  onReady = () => {
    this.onResize();
  };

  onComplete = () => {
    const raf = getRaf();
    raf.register("canvas", this.render);
  };
}
