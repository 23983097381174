const works = [
  {
    name: "Personal zucchero",
    model: null,
    url: "#",
  },
  {
    name: "Yabe",
    model: "/assets/models/table.glb",
    slug: "yabe",
  },
  {
    name: "Sita software",
    model: null,
    url: "#",
  },
  {
    name: "La royale",
    model: null,
    url: "#",
  },
  {
    name: "Le val-fayt",
    model: "/assets/models/bench.glb",
    slug: "le_val-fayt",
  },
  {
    name: "Carla Bravi",
    model: null,
    url: "#",
  },
  {
    name: "Les deux gueux",
    model: null,
    url: "#",
  },
  {
    name: "Sita group",
    model: null,
    url: "#",
  },
  {
    name: "Mba mutuelle",
    model: null,
    url: "#",
  },
  {
    name: "Relax",
    model: null,
    url: "#",
  },
  {
    name: "Coppers",
    model: null,
    url: "#",
  },
  {
    name: "Europadonna",
    model: "/assets/models/bench.glb",
    slug: "europadonna",
  },
  {
    name: "Unicraft",
    model: null,
    url: "#",
  },
  {
    name: "Camerhogne press",
    model: null,
    url: "#",
  },
  {
    name: "Fresche bontà",
    model: null,
    url: "#",
  },
  {
    name: "La caserma",
    model: null,
    url: "#",
  },
  {
    name: "Stucco",
    model: "/assets/models/table.glb",
    slug: "stucco",
  },
  {
    name: "Kancelaria ffm",
    model: null,
    url: "#",
  },
  {
    name: "Maps systems",
    model: "/assets/models/table.glb",
    slug: "maps_systems",
  },
  {
    name: "01 lola",
    model: null,
    url: "#",
  },
  {
    name: "Avewo",
    model: null,
    url: "#",
  },
  {
    name: "Jemmic",
    model: "/assets/models/bench.glb",
    slug: "jemmic",
  },
  {
    name: "Logement senior universel",
    model: null,
    url: "#",
  },
  {
    name: "La doncourt",
    model: null,
    url: "#",
  },
];

export default works;
