import "./style.css";
import layout from "./layout.html";

export default class CookieBanner {
  static selector = ".cookie-banner";

  constructor(block) {
    this.block = block;

    this.block.innerHTML = layout;
    this.actionNode = block.querySelector(".cookie-banner__action");
  }

  show = () => {
    this.block.classList.add("cookie-banner--visible");
  };

  hide = () => {
    this.block.classList.remove("cookie-banner--visible");
  };

  getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  onActionButtonClicked = (e) => {
    document.cookie = `cookie-banner--square-studio=true; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 365
    ).toUTCString()}`;

    this.hide();
  };

  onReady = () => {
    const cookie = this.getCookie("cookie-banner--square-studio");

    if (cookie === undefined) {
      this.show();

      this.actionNode.addEventListener("click", this.onActionButtonClicked);
    }
  };
}
