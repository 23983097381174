import "./style.css";

export default class Columns {
  static selector = ".columns";

  constructor(block) {
    this.block = block;
    document.addEventListener("keydown", this.toggleGrid);
  }

  toggleGrid = (e) => {
    if (e.ctrlKey && e.key.toLowerCase() === "g") {
      this.block.classList.toggle("columns--visible");
    }
  };
}
