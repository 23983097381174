import { getRaf } from "@app";
import { easeOutQuint } from "@utils/easing";
import clamp from "@utils/clamp";

export default class Model {
  constructor(model, slug, position) {
    this.model = model;
    this.slug = slug;
    this.position = position;
    this.raf = getRaf();
    this.model.material.transparent = true;
    this.model.material.opacity = 1;

    this.inOutAnimations = {
      duration: 1000,
      easing: easeOutQuint,
      out: false,
      styles: {
        translateY: {
          toValue: 100,
          fromValue: 0,
          current: 0,
          setValue: (progress) => {
            const value = !this.inOutAnimations.out
              ? this.inOutAnimations.styles.translateY.fromValue +
                (this.inOutAnimations.styles.translateY.toValue -
                  this.inOutAnimations.styles.translateY.fromValue) *
                  progress
              : this.inOutAnimations.styles.translateY.fromValue *
                (1 - progress);

            return value;
          },
        },

        opacity: {
          toValue: 1,
          fromValue: 0,
          current: 0,
          setValue: (progress) => {
            const value = !this.inOutAnimations.out
              ? this.inOutAnimations.styles.opacity.fromValue +
                (this.inOutAnimations.styles.opacity.toValue -
                  this.inOutAnimations.styles.opacity.fromValue) *
                  progress
              : this.inOutAnimations.styles.opacity.fromValue * (1 - progress);

            return value;
          },
        },
      },
    };
  }

  setPosition = (x, y) => {
    this.model.position.setX(x);
    this.model.position.setY(y);
  };

  layout = () => {
    this.model.material.opacity = this.inOutAnimations.styles.opacity.current;
  };

  render = (timestamp) => {
    if (!this.inOutAnimations.startTimestamp) {
      this.inOutAnimations.startTimestamp = timestamp;
    }

    const progress = this.inOutAnimations.easing(
      clamp(
        (timestamp - this.inOutAnimations.startTimestamp) /
          this.inOutAnimations.duration,
        0,
        1
      )
    );

    for (const key in this.inOutAnimations.styles) {
      this.inOutAnimations.styles[key].current =
        this.inOutAnimations.styles[key].setValue(progress);
    }

    this.layout();
  };

  resetInOutAnimation = (out) => {
    this.raf.unregister(this.slug);
    this.inOutAnimations.out = out;
    this.inOutAnimations.startTimestamp = null;

    for (const key in this.inOutAnimations.styles) {
      this.inOutAnimations.styles[key].fromValue =
        this.inOutAnimations.styles[key].current;
    }
  };

  show = () => {
    this.resetInOutAnimation(false);
    this.raf.register(this.slug, this.render);
  };

  hide = () => {
    this.resetInOutAnimation(true);
    this.raf.register(this.slug, this.render);
  };
}
