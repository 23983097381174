import "./style.css";
import layout from "./layout.html";

export default class Header {
  static selector = "header";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.toggleColumnsNode = block.querySelector(".header__toggle-columns");

    if (this.toggleColumnsNode) {
      this.toggleColumnsNode.addEventListener("click", () => {
        document.body.classList.toggle("columns-visible");
      });
    }
  }
}
