import "./style.css";
import layout from "./layout.html";

import clamp from "/utils/clamp.js";
import { easeInOutQuint } from "/utils/easing.js";
import { getRaf } from "@app";

export default class Loader {
  constructor() {
    this.block = document.querySelector(".loader");
    this.block.innerHTML = layout;
    this.wordNodes = this.block.querySelectorAll(".loader__logo__word span");
    this.wordNodes.forEach((node) => {
      node.style.width = `${node.scrollWidth}px`;
    });
    window.setTimeout(() => {
      this.block.classList.add("loader--ready");
    }, 200);

    this.progress = 0;
    this.imagesProgress0 = 0;
    this.imagesProgress = 0;
    this.timeProgress = 0;

    this.loadCallbacks = [];

    this.animation = {
      duration: 1000,
      ease: easeInOutQuint,
      fromValue: 0,
      toValue: 100,
      current: 0,
      setValue: (progress) => {
        this.animation.current = Math.round(this.animation.toValue * progress);
      },
    };

    const raf = getRaf();
    raf.register("loader", this.render);

    const resourcesToLoad = document.querySelectorAll("img[src]:not([src=''])");
    if (resourcesToLoad.length > 0) {
      this.resourcesAmount = resourcesToLoad.length;
      this.resourcesLoaded = 0;

      resourcesToLoad.forEach((r) => {
        let image = new Image();
        image.addEventListener("load", () => {
          this.resourcesLoaded += 1;

          this.imagesProgress = this.resourcesLoaded / this.resourcesAmount;
        });
        image.src = r.src;
      });
    } else {
      this.imagesProgress = 1;
    }
  }

  subscribe = (callback) => {
    this.loadCallbacks.push(callback);
  };

  animate = () => {};

  render = (timestamp) => {
    if (!this.animation.startTimestamp) {
      this.animation.startTimestamp = timestamp;
    }

    this.timeProgress = this.animation.ease(
      clamp(
        (timestamp - this.animation.startTimestamp) / this.animation.duration,
        0,
        1
      )
    );

    this.imagesProgress0 =
      this.imagesProgress0 +
      (this.imagesProgress - this.imagesProgress0) * 0.09;

    this.progress = Math.min(this.timeProgress, this.imagesProgress0);

    this.animation.setValue(this.progress);

    this.animate();

    if (this.animation.current === 100) {
      const raf = getRaf();
      raf.unregister("loader");

      this.block.classList.add("loader--hidden");

      window.setTimeout(() => {
        this.loadCallbacks.forEach((callback, i) => {
          callback();
          this.loadCallbacks.splice(i, 1);
        });
      }, 600);

      window.setTimeout(() => {
        document.body.classList.add("loaded");
        this.block.style.pointerEvents = "none";
      }, 1600);
    }
  };

  onReady = () => {};
}
