import "@styles/font.css";
import "@styles/typography.css";
import "@styles/base.css";
import "@styles/colors.css";
import "@styles/common.css";
import "@styles/z-indexes.css";
import "@styles/transitions.css";

/* Components */
import Header from "@blocks/Header";
import Footer from "@blocks/Footer";
import Loader from "@blocks/Loader";
import Splash from "@blocks/Splash";
import LocalTime from "@blocks/LocalTime";
import Works from "@blocks/Works";
import CookieBanner from "@blocks/CookieBanner";

/* Blocks */
import AnimatedText from "@components/AnimatedText";
import Canvas from "@components/Canvas";
import "@components/Button";
import Columns from "@components/Columns";

/* Pages */
import "@pages/privacy";

/* Utils */
import Raf from "@utils/Raf";
import Observer from "@utils/Observer";

const MODULES = [
  Header,
  Footer,
  Splash,
  LocalTime,
  Works,
  AnimatedText,
  Canvas,
  CookieBanner,
  Columns,
];

class App {
  lastIndex = 0;

  constructor() {
    window.addEventListener("DOMContentLoaded", this.prepareToInitApp);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("scroll", this.onScroll);
  }

  prepareToInitApp = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js").then(
        function (registration) {
          // Registration was successful
          console.log(
            "ServiceWorker registration successful with scope: ",
            registration.scope
          );
        },
        function (err) {
          // registration failed :(
          console.log("ServiceWorker registration failed: ", err);
        }
      );
    }

    this.observer = new Observer();
    this.raf = new Raf();

    this.initApp();
  };

  initApp = () => {
    this.lastIndex = 0;

    this.instances = MODULES.flatMap((Module) => {
      if ("selector" in Module) {
        const blocks = [...document.querySelectorAll(Module.selector)];

        return blocks.map((block) => {
          block.setAttribute("data-instance-index", this.lastIndex);
          this.lastIndex += 1;
          return new Module(block);
        });
      }
      return null;
    }).filter(Boolean);

    window.setTimeout(() => {
      for (const instance of this.instances) {
        if (instance.onReady) {
          instance.onReady();
        }
      }

      const loaderNode = document.querySelector(".loader");
      if (loaderNode) {
        this.loader = new Loader();
        this.loader.onReady();
        if (this.loader.subscribe) {
          this.loader.subscribe(() => {
            for (const instance of this.instances) {
              if (instance.onComplete) {
                instance.onComplete();
              }
            }
          });
        }
      } else {
        for (const instance of this.instances) {
          if (instance.onComplete) {
            instance.onComplete();
          }
        }
      }
    }, 200);
  };

  onResize = (e) => {
    if (!this.instances) {
      return;
    }

    for (const instance of this.instances) {
      if (instance.onResize) {
        // instance.onResize(e, { changedView });
        instance.onResize(e);
      }
    }
  };

  onScroll = (e) => {
    if (!this.instances) {
      return;
    }

    for (const instance of this.instances) {
      if (instance.onScroll) {
        instance.onScroll(e);
      }
    }
  };
}

window.App = new App();

export const getRaf = () => {
  return window.App.raf;
};

export const getObserver = () => {
  return window.App.observer;
};

export const getInstance = (el) => {
  const instanceIndex = el.getAttribute("data-instance-index");

  if (!instanceIndex) {
    return null;
  }

  return window.App.instances[parseInt(instanceIndex, 10)];
};
