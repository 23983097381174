import layout from "./layout.html";
import "./style.css";

import clamp from "@utils/clamp.js";
import { easeOutQuint } from "@utils/easing.js";
import { getRaf } from "@app";
import { getObserver } from "@app";

export default class LocalTime {
  static selector = ".local-time";

  constructor(block) {
    this.block = block;
    this.block.innerHTML = layout;
    this.timeNode = block.querySelector(".local-time__time");

    this.date = new Date();
  }

  updateTime = () => {
    this.date = new Date();

    this.timeNode.textContent = this.date.toLocaleTimeString("it-IT");
  };

  onReady = () => {
    this.raf = getRaf();
    if (this.raf) {
      this.raf.register(this.block.dataset.instanceIndex, this.updateTime);
    }
  };
}
