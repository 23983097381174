import "./style.css";
import { getInstance } from "@app";
import layout from "./layout.html";
import works from "./works";

export default class Works {
  static selector = ".works";

  constructor(block) {
    this.block = block;
    this.canvasNode = document.querySelector("canvas");

    this.block.innerHTML = layout;

    this.workTemplate = block.querySelector("#works__work");
    this.worksList = block.querySelector(".works__list");

    this.worksWithModel = [];
  }

  setWorksWithModel = () => {
    this.block
      .querySelectorAll(".works__list__work[data-model]")
      .forEach((node) => {
        this.worksWithModel.push({
          node: node,
          position: node.getBoundingClientRect(),
        });
      });
  };

  updateWorksWithModelPositions = () => {
    this.worksWithModel.forEach((work) => {
      work.position = work.node.getBoundingClientRect();
      this.canvas.updateModelPosition(work.node.href, work.position);
    });
  };

  addWorksModelToTheCanvas = () => {
    this.worksWithModel.forEach((work) => {
      this.canvas.addModel(
        work.node.dataset.model,
        work.position,
        work.node.href
      );
    });
  };

  showModel = (event) => {
    const target = event.currentTarget;
    this.canvas.showModel(target.href);
  };

  hideModel = (event) => {
    const target = event.currentTarget;
    this.canvas.hideModel(target.href);
  };

  setupWorks = () => {
    works.forEach((work) => {
      const workNode = this.setupWork(work);

      if (workNode) {
        this.worksList.appendChild(workNode);
      }
    });
  };

  setupWork = (work) => {
    let workNode = this.workTemplate.content.firstElementChild.cloneNode(true);

    if (workNode) {
      workNode.textContent = `${work.name}.`;

      if (work.slug) {
        workNode.href = work.slug;
        workNode.classList.add("label-1");
        workNode.dataset.model = work.model;
        workNode.addEventListener("mouseenter", this.showModel);
        workNode.addEventListener("mouseleave", this.hideModel);
      } else {
        workNode.classList.add("gray-3");
        workNode.href = work.url;
        workNode.target = "_blank";
      }

      return workNode;
    } else {
      return null;
    }
  };

  onReady = () => {
    this.setupWorks();
  };

  onComplete = () => {
    this.canvas = getInstance(this.canvasNode);
    this.setWorksWithModel();
    this.addWorksModelToTheCanvas();
  };

  onResize = () => {
    this.updateWorksWithModelPositions();
  };
}
